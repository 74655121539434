<template>
  <CommonsModalsModalBody :svg="svg" height="auto">
    <template #title> Vous allez envoyer la facture par mail </template>

    <template #content>
      <div class="modal-content ssd-container">
        <div class="ssd-form">
          <div class="ssd-row">
            <uds-input-string
              label="Destinataire"
              is-required
              :value="form.to"
              :error="err.to"
              @change="form.to = $event || null"
            />
          </div>
          <div class="ssd-row">
            <uds-input-string
              label="Cci"
              :value="form.bcc"
              :error="err.bcc"
              @change="form.bcc = $event || null"
            />
          </div>
          <div class="ssd-row">
            <uds-input-string
              label="Objet"
              is-required
              :value="form.subject"
              :error="err.subject"
              @change="form.subject = $event || ''"
            />
          </div>

          <label class="uds-input-label" style="margin-bottom: -10px"
            >Message <span :style="{ color: udsColors.udsError500 }">*</span></label
          >
          <CommonsInputsTextEditor v-model="form.content" />
        </div>
      </div>
    </template>

    <template #footer>
      <uds-main-button size="small" type="tertiary" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <uds-main-button
        size="small"
        :loading="isLoading"
        :disabled="!isValid"
        @click="isValid && sendMail()"
      >
        Valider
      </uds-main-button>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type { CreateCommercialMail } from "@silexpert/core";

const societyStore = useSocietyStore();
const { isComptalib } = useBrandsComposable();

const emit = defineEmits(["cancel", "close"]);

const udsColors = getUdsColors();

const props = defineProps<{
  defaultEmail?: string;
  idFile: number;
}>();

const svg = isComptalib() ? "ComptalibPaperPlaneSvg" : "SendMailSvg";

const isLoading = ref(false);

const form = ref<CreateCommercialMail>({
  to: props.defaultEmail ?? "",
  bcc: undefined,
  subject: "Facture",
  content: `<p>Vous trouverez ci-joint votre facture.</p><br/><p>Merci de votre confiance !</p><br/><br/><p>${societyStore?.society?.name}</p>`,
});

const err = computed<{ [key: string]: string | null }>(() => {
  const { to, bcc, subject, content } = form.value;

  return {
    to: !isEmailString(to) ? "L'adresse e-mail est invalide." : null,
    receiversBcc: isDefined(bcc) && !isEmailString(bcc) ? "L'adresse e-mail est invalide." : null,
    subject: (subject?.length ?? 0) <= 0 ? "L'objet ne doit pas être vide" : null,
    content: content.length === 0 ? "Le message ne doit pas être vide" : null,
  };
});

const isValid = computed(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

async function sendMail() {
  const { to, bcc, subject, content } = form.value;
  isLoading.value = true;
  await $silex()
    .invoice.sendMail(props.idFile, {
      to,
      ...(isDefined(bcc) ? { bcc } : {}),
      subject,
      content,
    })
    .catch((error: any) => {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    });
  isLoading.value = false;
  emit("close");
}
</script>

<style lang="scss" scoped>
.ssd-container {
  font-style: normal;
  font-weight: $uds-weight-450;
  display: flex;
  flex-direction: column;
  gap: $uds-spacing-3;
  max-height: 670px;
  padding-right: 10px;
  padding-bottom: 10px;
  .ssd-title {
    font-weight: $uds-weight-500;
    font-size: 18px;
    line-height: 150%;
  }
  .ssd-history-table {
    margin-top: $uds-spacing-1;
    border: 0.5px solid $uds-neutral-600;
    box-shadow: 0px 2px 5px rgba(1, 34, 223, 0.05);
    border-radius: 10px;
    .ssdh-table-row {
      display: flex;
      align-items: center;
      padding: $uds-spacing-1 $uds-spacing-2;
      width: 100%;
      border-top: 0.5px solid $uds-neutral-600;
      &.--header {
        font-size: 14px;
        line-height: 150%;
        color: $uds-neutral-700;
        border-top: 0;
      }
      .cell {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        &.--date {
          width: 110px;
        }
        span {
          color: $uds-neutral-700;
          padding-top: $uds-spacing-0_5;
        }
      }
    }
  }
  .ssd-nohistory {
    margin-top: $uds-spacing-1;
    color: $uds-neutral-700;
    font-size: 14px;
  }
  .ssd-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 14px;

    .ssd-row {
      display: flex;
      gap: $uds-spacing-1;

      > * {
        flex-basis: 100%;
        flex-grow: 1;
        height: fit-content;
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }

  .ssd-row-files {
    display: flex;
    gap: $uds-spacing-1;
    .ssd-file-container {
      display: flex;
      gap: $uds-spacing-1;
      padding: $uds-spacing-0_5 $uds-spacing-1;
      align-items: center;
      background-color: $uds-neutral-200;
      border-radius: $uds-radius-1;
    }
  }

  .row-error {
    color: #771d1d;
  }
}

.footer-actions {
  text-align: right;

  .btn-icon {
    margin-right: $uds-spacing-1;
  }
}
</style>
